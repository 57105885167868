import {
  ssrWindow
} from "./chunk-TGVXP7CR.mjs";
import {
  ssrDocument
} from "./chunk-2GW6Q5BU.mjs";

// src/env.tsx
import {
  createContext,
  startTransition,
  useContext,
  useMemo,
  useState,
  useEffect
} from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var mockEnv = {
  window: ssrWindow,
  document: ssrDocument
};
var defaultEnv = typeof window !== "undefined" ? { window, document } : mockEnv;
var EnvironmentContext = createContext(defaultEnv);
EnvironmentContext.displayName = "EnvironmentContext";
function useEnvironment() {
  return useContext(EnvironmentContext);
}
function EnvironmentProvider(props) {
  const { children, environment: environmentProp } = props;
  const [node, setNode] = useState(null);
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);
  const context = useMemo(() => {
    if (environmentProp) {
      return environmentProp;
    }
    const doc = node == null ? void 0 : node.ownerDocument;
    const win = node == null ? void 0 : node.ownerDocument.defaultView;
    const env = doc ? { document: doc, window: win } : defaultEnv;
    return env;
  }, [node, environmentProp]);
  return /* @__PURE__ */ jsxs(EnvironmentContext.Provider, { value: context, children: [
    children,
    !environmentProp && mounted && /* @__PURE__ */ jsx(
      "span",
      {
        id: "__chakra_env",
        hidden: true,
        ref: (el) => {
          startTransition(() => {
            if (el)
              setNode(el);
          });
        }
      }
    )
  ] });
}
EnvironmentProvider.displayName = "EnvironmentProvider";

export {
  useEnvironment,
  EnvironmentProvider
};
