// src/use-media-query.ts
import { useEnvironment } from "@chakra-ui/react-env";
import { useEffect, useState } from "react";
function useMediaQuery(query, options = {}) {
  const { ssr = true, fallback } = options;
  const env = useEnvironment();
  const queries = Array.isArray(query) ? query : [query];
  let fallbackValues = Array.isArray(fallback) ? fallback : [fallback];
  fallbackValues = fallbackValues.filter((v) => v != null);
  const [value, setValue] = useState(() => {
    return queries.map((query2, index) => ({
      media: query2,
      matches: ssr ? !!fallbackValues[index] : env.window.matchMedia(query2).matches
    }));
  });
  useEffect(() => {
    setValue(
      queries.map((query2) => ({
        media: query2,
        matches: env.window.matchMedia(query2).matches
      }))
    );
    const mql = queries.map((query2) => env.window.matchMedia(query2));
    const handler = (evt) => {
      setValue((prev) => {
        return prev.slice().map((item) => {
          if (item.media === evt.media)
            return { ...item, matches: evt.matches };
          return item;
        });
      });
    };
    mql.forEach((mql2) => {
      if (typeof mql2.addListener === "function") {
        mql2.addListener(handler);
      } else {
        mql2.addEventListener("change", handler);
      }
    });
    return () => {
      mql.forEach((mql2) => {
        if (typeof mql2.removeListener === "function") {
          mql2.removeListener(handler);
        } else {
          mql2.removeEventListener("change", handler);
        }
      });
    };
  }, [env.window]);
  return value.map((item) => item.matches);
}

export {
  useMediaQuery
};
